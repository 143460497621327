import { useMemo, useState } from "react";
import styled from "styled-components";

import BottomModal from "../Onboarding/OnboardingComponents/BottomModal";

import { Icons } from "../../../assets/images/images";

interface IProps {
  openingHours: {
    week_day: number;
    opening_hour: string;
    closing_hour: string;
  }[];
}

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const OpeningHours = ({openingHours}: IProps) => {
  const [openSeeAllHoursPopup, setOpenSeeAllHoursPopup] = useState<boolean>(false);

  const date = new Date();
  const dayNumber = date.getDay();

  const isOpen = useMemo(() => {
    const currentTime = +(String(date.getHours()) + String(date.getMinutes()));
  
    const splittedOpeningHour = openingHours.find((openingHour) => openingHour.week_day === dayNumber)?.opening_hour?.split(':');
    const splittedClosingHour = openingHours.find((openingHour) => openingHour.week_day === dayNumber)?.closing_hour?.split(':');

    let isOpen = false;
    if(splittedOpeningHour && splittedClosingHour) {
      const dayOpeningHour = +(splittedOpeningHour[0] + splittedOpeningHour[1]);
      const dayClosingHour = +(splittedClosingHour[0] + splittedClosingHour[1]);
      isOpen = dayOpeningHour <= currentTime && ((dayClosingHour === 0) ? 1200 : dayClosingHour) >= currentTime;
    }
    return isOpen;
  }, [openingHours]);

  const convertHours = (hour: string | undefined) => {
    if(!hour) return "";
    
    const splittedHour = hour.split(':');
    const ampm = +splittedHour[0] >= 12 ? 'PM' : 'AM';

    return `${+splittedHour[0]}:${splittedHour[1]} ${ampm}`;
  }

  const currentDayOpeningClosingHours = openingHours.find((openingHour) => openingHour.week_day === dayNumber);

  return(
    <Container>
      <Icon 
        src={Icons.wallclock} 
        width="18" 
        alt="" 
      />
      {(openingHours[0].opening_hour && openingHours[0].closing_hour) ? <>
        <OpenNow>{isOpen ? "Open Now" : "Close Now"}</OpenNow>
        {currentDayOpeningClosingHours && <Hours>{convertHours(currentDayOpeningClosingHours?.opening_hour)} - {convertHours(currentDayOpeningClosingHours?.closing_hour)}</Hours>}
        <SeeAllButton onClick={() => setOpenSeeAllHoursPopup(true)}>See all hours</SeeAllButton>
      </> : <OpenNow>Open 24 hours</OpenNow>}
      {openSeeAllHoursPopup && <BottomModal 
        openBottomModal={openSeeAllHoursPopup} 
        closeBottomModal={() => setOpenSeeAllHoursPopup(false)}
      >
        <PopupDays>
          {[...openingHours].sort((a, b) => a.week_day - b.week_day).map((day) => {
            return <PopupDayHours>
              <DayDetail>{days[day.week_day - 1]}</DayDetail>
              <DayDetail>{convertHours(day.opening_hour)} - {convertHours(day.closing_hour)}</DayDetail>
            </PopupDayHours>
          })}
        </PopupDays>
      </BottomModal>}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  gap: 5px;
`;

const Icon = styled.img`
  width: 20px;
`;

const OpenNow = styled.h3`
  font-weight: 700;
  font-size: 16px;
  white-space: nowrap;
  margin: 0;
`;

const Hours = styled.h3`
  font-weight: 500;
  font-size: 16px;
  margin: 0;
`;

const SeeAllButton = styled.button`
  color: #80809B;
  font-weight: 500;
  font-size: 14px;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const PopupDays = styled.div`
  width: 100%;
  margin-top: 40px;
`;

const PopupDayHours = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const DayDetail = styled.h3`
  color: #80809B;
  font-weight: 400;
  font-size: 16px;
`;

export default OpeningHours;